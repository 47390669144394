/* cart.css */

.checkout-btn {
    background-color: #007bff; /* Blue color for the button */
    color: #fff; /* White text color */
    padding: 10px 20px; /* Padding around the button text */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor style */
    margin-top: 21px;
  }
  
 
  

  /* SmallSlie css */
  .bannerSection{padding: 25px 0px; }

/* .bannerSection .box{overflow: hidden; border-radius: 15px; cursor: pointer;}
.bannerSection .box:hover img{transform: scale(1.1)} */


/* Custom css */


.custom-prev-arrow {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 1;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Custom styles for next arrow */
  .custom-next-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 1;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  

  .menu-button {
    background-color: #FFD700; 
    color: #FFFFFF; 
    border: none; 
    padding: 10px 20px; 
    font-size: 16px; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s ease; 
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .menu-button:hover {
    background-color: #FFA500;
  }
  .menu-heading {
    padding-top: 55px;
}

.menu-item {
  text-align: center;
}

.menu-item img {
  width: 100px;
  height: 100px; 
  border-radius: 50%; 
  margin-bottom: 5px; 
}

.menu-item p {
  margin: 0; 
}
.align-items-center{
  padding-top: 22px;
}
.banner-image {
  max-width: 100%;
  height: auto;
  max-height: 150px; 
 
}
.banner-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 47px;
  font-weight: bold;
 
  padding-bottom: 40px;
}
.menu-heading{
  text-align: center;
  margin-bottom: 20px;
  font-size: 47px;
  font-weight: bold;
 
  padding-bottom: 40px;

}

/* Camera page */
/* CarouselComponent.css */
.carousel-image {
  width: 100%;
  height: auto;
}

/* end camera */

.carouselll{
  width: 100%;
  height: 309px;
}


@media (max-width: 320px) {

    .bannerSection {
      padding: 15px 0px; 
    }
  
    .bannerSection .box {
      border-radius: 10px; 
    }
  
    .menu-heading {
      padding-top: 45px;
    }
  
    .menu-item img {
      width: 60px; 
      height: 60px; 
    }
    .d-flex{
      overflow-x: scroll;
    }
    .menu-item{
      width: 400px;
    }
  }
  @media (max-width: 400px) {
    .bannerSection {
      padding: 15px 0px; 
    }
  
    .bannerSection .box {
      border-radius: 10px; 
    }
  
    .menu-heading {
      padding-top: 45px;
    }
  
    .menu-item img {
      width: 60px; 
      height: 60px; 
    }
    .d-flex{
      overflow-x: scroll;
    }
    .menu-item{
      width: 400px;
    }
    .carouselll{
      width: 100%;
        height: 167px;
      }


  }
  @media (max-width: 575px) {
    .bannerSection {
      padding: 15px 0px; 
    }
  
    .bannerSection .box {
      border-radius: 10px; 
    }
  
    .menu-heading {
      padding-top: 45px;
    }
  
    .menu-item img {
      width: 60px; 
      height: 60px; 
    }
    .d-flex{
      overflow-x: scroll;
    }
    .menu-item{
      width: 400px;
    }
    .carouselll{
      width: 100%;
        height: 167px;
      }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .bannerSection {
      padding: 15px 0px; 
    }
  
    .bannerSection .box {
      border-radius: 10px; 
    }
  
    .menu-heading {
      padding-top: 45px;
    }
  
    .menu-item img {
      width: 60px; 
      height: 60px; 
    }
    .d-flex{
      overflow-x: scroll;
    }
    .menu-item{
      width: 400px;
    }
    .carouselll{
      width: 100%;
        height: 167px;
      }

  }
  @media (min-width: 768px) and (max-width: 991px) {
    .bannerSection {
      padding: 15px 0px; 
    }
  
    .bannerSection .box {
      border-radius: 10px; 
    }
  
    .menu-heading {
      padding-top: 45px;
    }
  
    .menu-item img {
      width: 60px; 
      height: 60px; 
    }
    .d-flex{
      overflow-x: scroll;
    }
    .menu-item{
      width: 400px;
    }
    .carouselll{
      width: 100%;
        height: 167px;
      }

    

  }

  @media (min-width: 320px) and (max-width: 400px) {
  .carouselll{
    width: 100%;
      height: 167px;
    }
  }




