.terms-condition {
    font-family: Arial, sans-serif;
    color: #333;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: justify;
    background-color: #f9f9f9;
  }
  
  .container {
    margin: 0 auto;
  }
  
  .terms-page h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .last-updated {
    font-size: 0.9em;
    color: #777;
    margin-bottom: 1em;
  }
  
  .terms-content p {
    margin: 1em 0;
  }
  
  .terms-content h2 {
    font-size: 1.5em;
    margin-top: 1.5em;
  }
  
  .terms-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .terms-content ul li {
    margin: 0.5em 0;
    padding-left: 1.5em;
    position: relative;
  }
  
  .terms-content ul li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #333;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  